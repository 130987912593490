body {
  font-family: 'Pretendard', 'Mulish', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
}

header,
footer,
nav,
main {
  width: auto;
}

input {
  border: none;
  outline: none;
}

a {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
}

button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

[class*='col-'] {
  float: left;
}

.common-wrapper {
  width: 100%;
  max-width: 1480px;
  margin: 0 auto;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.3333333%;
}

.col-6 {
  width: 50%;
}

.col-12 {
  width: 100%;
}

.clearfixafter:after {
  content: '';
  display: block;
  clear: both;
}

.clearfix {
  content: '';
  display: block;
  clear: both;
}

.pc-only {
  display: block;
}

.mo-only {
  display: none;
}

html {
  height: 100%;
}

body {
  min-height: 100vh;
  overflow: auto;
}
/* body::-webkit-scrollbar {
  display: none;
} */

html.static,
.static body {
  height: auto;
  min-width: initial;
}

button {
  outline: none;
}

.wrapper {
  max-width: 1260px;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  text-overflow: ellipsis;
  max-width: 100%;
}

.background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.opacity-0 {
  opacity: 0;
}

.nd-pc-mobile-br {
  display: block;
}

.nd-mobile-br {
  display: none;
}

.nd-pc-br {
  display: block;
}

.popup-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.popup-bg.opened {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.popup {
  position: absolute;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  transition: 0.3s all ease-in-out;
}

.opened .popup {
  transform: translate(-50%, -50%) scale(1);
  transition: 0.3s all ease-in-out;
}

.no-scroll {
  overflow: hidden;
}

/* ::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: #f3f3f3;
}

::-webkit-scrollbar-thumb {
  background-color: #c3def1;
  border-radius: 20px;
  border: 0px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c3def1;
} */

/* 태블릿 */
@media (max-width: 1024px) {
  .nd-mobile-br {
    display: block;
  }

  .nd-pc-br {
    display: none;
  }
}

/* 모바일 */
@media (max-width: 767px) {
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Pretendard-Regular.woff2'), url('../fonts/Pretendard-Regular.woff'),
    url('../fonts/Pretendard-Regular.subset.woff2'), url('../fonts/Pretendard-Regular.subset.woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Pretendard-Bold.woff2'), url('../fonts/Pretendard-Bold.woff'),
    url('../fonts/Pretendard-Bold.subset.woff2'), url('../fonts/Pretendard-Bold.subset.woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  font-style: normal;
  src: url('../fonts/Pretendard-ExtraBold.woff2'), url('../fonts/Pretendard-ExtraBold.woff'),
    url('../fonts/Pretendard-ExtraBold.subset.woff2'),
    url('../fonts/Pretendard-ExtraBold.subset.woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/Pretendard-Light.woff2'), url('../fonts/Pretendard-Light.woff'),
    url('../fonts/Pretendard-Light.subset.woff2'), url('../fonts/Pretendard-Light.subset.woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  font-style: normal;
  src: url('../fonts/Pretendard-ExtraLight.woff2'), url('../fonts/Pretendard-ExtraLight.woff'),
    url('../fonts/Pretendard-ExtraLight.subset.woff2'),
    url('../fonts/Pretendard-ExtraLight.subset.woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/Pretendard-Medium.woff2'), url('../fonts/Pretendard-Medium.woff'),
    url('../fonts/Pretendard-Medium.subset.woff2'), url('../fonts/Pretendard-Medium.subset.woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/Pretendard-SemiBold.woff2'), url('../fonts/Pretendard-SemiBold.woff'),
    url('../fonts/Pretendard-SemiBold.subset.woff2'),
    url('../fonts/Pretendard-SemiBold.subset.woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  font-style: normal;
  src: url('../fonts/Pretendard-Thin.woff2'), url('../fonts/Pretendard-Thin.woff'),
    url('../fonts/Pretendard-Thin.subset.woff2'), url('../fonts/Pretendard-Thin.subset.woff');
}
