.nd-main-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.nd-main-container {
  width: 658px;
  padding: 151px 87px 196px 87px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.nd-main-title {
  text-align: center;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nd-sub-title {
  margin-bottom: 32px;
}

.nd-main-input {
  width: 100%;
  margin-bottom: 42px;
  position: relative;
}

.nd-main-button {
  margin-top: 87px;
  margin-bottom: 31px;
  display: flex;
}

.nd-main-bottom {
  padding-left: 25px;
  box-sizing: border-box;
}

.nd-sub-container {
  width: 626px;
  padding: 291px 67px 266px 67px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  backdrop-filter: blur(30px);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.nd-sub-container::before {
  content: '';
  opacity: 0.9;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  backdrop-filter: blur(30px);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  opacity: 0.08;
}

.nd-sub-mini-container {
  color: #ffffff;
  position: relative;
}

.nd-password-inner {
  width: 658px;
  padding: 229px 110px 210px 110px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.nd-password-container {
  width: 658px;
  padding: 120px 87px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.nd-main-inner {
  width: 658px;
  padding: 48px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.nd-between-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 42px;
  color: #212121;
  position: relative;
}

.nd-sub-inputbox {
  position: absolute;
  top: 50px;
  left: 0;
  display: flex;
  align-items: center;
}

.search_list {
  max-width: calc(100% - 60px);
  width: auto;
  height: calc(100vh - 74px);
  background-color: #f8fafd;
  padding-left: 60px;
  transition: 0.3s all ease-in-out;
}

.search_list.opened {
  /* max-width: calc(100% - 264px);  */
  /* width: 100%; */
  transition: 0.5s all ease-in-out;
  padding-left: 16px;
}

.content-wrapper {
  max-width: 1250px;
  width: 100%;
  padding-bottom: 10px;
}

.active + .content-wrapper {
  width: calc(100% - 284px);
  transition: 0.5s all ease-in-out;
  padding-left: 16px;
}

.mobile-button-bg {
  display: none;
}

@media all and (max-width: 1200px) {
  .nd-main-container,
  .nd-main-inner,
  .nd-password-container,
  .nd-password-inner {
    width: 100vw;
    height: 100vh;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nd-sub-container {
    display: none;
  }

  .mobile-button-bg {
    display: flex;
    width: 100%;
    margin-bottom: 24px;
  }

  .nd-main-title {
    width: initial;
    min-width: 300px;
  }

  .nd-sub-title {
    margin-bottom: 32px;
    /* position: relative;
    width: 100%; */
  }

  .nd-main-button {
    margin-top: 24px;
  }

  .wide.nd-main-button {
    width: 100%;
  }

  .nd-main-bottom {
    padding-left: 0;
  }

  .nd-main-input,
  .nd-between-input {
    margin-bottom: 24px;
  }
} ;
